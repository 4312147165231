<template>
  <div class="py-3 pl-3">
    <b-overlay
      :show="showLoading"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <div v-if="services.length">
        <div class="accordion-label__content">
          <div class="row align-items-center w-100">
            <div class="col-12 col-lg-4">
              Название подуслуги
            </div>
            <div class="col-12 col-lg-3">
              Slug
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="services.length"
        class="accordion"
        role="tablist"
      >
        <div
          v-for="(service, index) in services"
          :key="index"
          class="accordion__item"
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row w-100 align-items-center">
                <div class="col-12 col-lg-4">
                  <div class="d-flex align-items-center">
                    <div
                      class="mr-2"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="selectedServices.includes(service.slug)"
                        @change="toggleCheckService(service.slug)"
                      />
                    </div>
                    {{ service.name }}
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <code>{{ service.slug }}</code>
                </div>
                <div class="col-12 col-lg-3">
                  <div
                    v-if="!service.is_read_only"
                    class="accordion-label-custom"
                  >
                    <div class="accordion-label-custom__icon">
                      <feather-icon
                        icon="StarIcon"
                        size="12"
                      />
                    </div>
                    <div class="accordion-label-custom__text">
                      Custom
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-2">
                  <div
                    v-if="!service.is_read_only"
                    class="d-flex justify-content-end align-items-center"
                  >
                    <b-button
                      v-b-tooltip.hover.left="'Редактировать'"
                      variant="link"
                      size="sm"
                      @click.stop="editedServiceSlug = service.slug"
                    >
                      <feather-icon
                        icon="Edit3Icon"
                        size="18"
                      />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover.left="'Удалить'"
                      variant="link"
                      size="sm"
                      @click.stop="deletedServiceSlug = service.slug"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="18"
                      />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        У этой услуги пока нет подкатегорий
      </div>
      <b-button
        class="mt-2"
        variant="outline-primary"
        :disabled="disabledButton"
        @click.prevent="showAddPopup = true"
      >
        Добавить услугу
      </b-button>

      <add-service-popup
        v-if="showAddPopup"
        :parent-service-slug="parentServiceSlug"
        @added="addService"
        @hidden="showAddPopup = false"
      />
      <delete-service-popup
        v-if="deletedServiceSlug"
        :service-slug="deletedServiceSlug"
        @deleted="deleteService"
        @hidden="deletedServiceSlug = null"
      />
      <edit-service-popup
        v-if="editedServiceSlug"
        :service-slug="editedServiceSlug"
        @updated="updateService"
        @hidden="editedServiceSlug = null"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BFormCheckbox, BButton, VBTooltip,
} from 'bootstrap-vue'
import SiteService from '@/api/http/models/site/service/SiteService'
import { mapGetters } from 'vuex'
import AddServicePopup from './AddServicePopup.vue'
import DeleteServicePopup from './DeleteServicePopup.vue'
import EditServicePopup from './EditServicePopup.vue'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BButton,
    AddServicePopup,
    DeleteServicePopup,
    EditServicePopup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    parentServiceSlug: {
      type: String,
      required: true,
    },
    parentServiceIsSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLoading: true,
      services: [],
      selectedServices: [],
      showAddPopup: false,
      editedServiceSlug: null,
      deletedServiceSlug: null,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    disabledButton() {
      return !this.parentServiceIsSelected
    },
  },
  watch: {
    parentServiceIsSelected: {
      handler() {
        this.initial()
      },
    },
  },
  async created() {
    await this.initial()
  },
  methods: {
    emitChangeSubService() {
      this.$emit('changeSubService', {
        serviceSlug: this.parentServiceSlug,
        subServicesAllChecked: this.selectedServices.length === this.services.length,
      })
    },
    async initial() {
      this.showLoading = true
      const response = await SiteService.getAll(this.currentSite.slug, this.parentServiceSlug)

      if (response.data) {
        this.selectedServices = response.data.filter(service => service.is_selected).map(service => service.slug)
        this.services = await response.data
      }
      this.showLoading = false
    },
    async toggleCheckService(slug) {
      const serviceSlug = slug.toString()
      const index = this.selectedServices.findIndex(a => a === serviceSlug)
      if (index !== -1) {
        if (await this.unselectService(serviceSlug)) {
          this.selectedServices.splice(index, 1)
        }
        this.emitChangeSubService()
      } else if (this.parentServiceIsSelected) {
        if (await this.selectService(serviceSlug)) {
          this.selectedServices.push(serviceSlug)
        }
        this.emitChangeSubService()
      } else {
        this.$emit('selectedSubService', this.parentServiceSlug, async () => {
          if (await this.selectService(serviceSlug)) {
            this.selectedServices.push(serviceSlug)
          }
          this.emitChangeSubService()
        })
      }
    },
    async selectService(serviceSlug) {
      const response = await SiteService.select(this.currentSite.slug, serviceSlug)
      return response.status === 'success'
    },
    async unselectService(serviceSlug) {
      const response = await SiteService.unselect(this.currentSite.slug, serviceSlug)
      return response.status === 'success'
    },
    addService(service) {
      this.services.unshift(service)
      if (service.is_selected) {
        this.selectedServices.push(service.slug)
      }
      this.emitChangeSubService()
    },
    deleteService(serviceSlug) {
      const index = this.services.findIndex(cur => cur.slug === serviceSlug)
      const selIndex = this.selectedServices.findIndex(cur => cur === serviceSlug)

      if (index !== -1) {
        this.services.splice(index, 1)
      }

      if (selIndex !== -1) {
        this.selectedServices.splice(selIndex, 1)
      }
      this.emitChangeSubService()
    },
    updateService(service) {
      const index = this.services.findIndex(cur => cur.slug === service.old_service.slug)
      const selIndex = this.selectedServices.findIndex(cur => cur === service.old_service.slug)

      if (index !== -1) {
        this.services.splice(index, 1, service.new_service)
      }

      if (selIndex !== -1) {
        this.selectedServices.splice(selIndex, 1)
      }

      if (service.new_service.is_selected) {
        this.selectedServices.push(service.new_service.slug)
      }
      this.emitChangeSubService()
    },
  },
}
</script>
