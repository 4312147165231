import HTTPService from '@/api/http/HTTPService'
import SiteServiceInterface from './SiteServiceInterface'
import SiteServiceSortInterface from './SiteServiceSortInterface'

export default {
  getAll: (siteSlug: string, parentSlug: string | null) => HTTPService.get(`site/${siteSlug}/service${parentSlug ? `?parent_slug=${parentSlug}` : ''}`),
  add: (siteSlug: string, payload: SiteServiceInterface) => HTTPService.post(`site/${siteSlug}/service`, payload),
  getOne: (siteSlug: string, serviceSlug: string) => HTTPService.get(`site/${siteSlug}/service/${serviceSlug}`),
  update: (siteSlug: string, serviceSlug: string, payload: SiteServiceInterface) => HTTPService.put(`site/${siteSlug}/service/${serviceSlug}`, payload),
  delete: (siteSlug: string, serviceSlug: string) => HTTPService.delete(`site/${siteSlug}/service/${serviceSlug}`),
  select: (siteSlug: string, serviceSlug: string, include_children: boolean) => HTTPService.post(`site/${siteSlug}/service/${serviceSlug}/select`, {
    include_children,
  }),
  unselect: (siteSlug: string, serviceSlug: string) => HTTPService.post(`site/${siteSlug}/service/${serviceSlug}/unselect`),
  getForSort: (siteSlug: string, parentSlug: string | null) => HTTPService.get(`site/${siteSlug}/service/reorder${parentSlug ? `?parent_slug=${parentSlug}` : ''}`),
  sort: (siteSlug: string, payload: SiteServiceSortInterface) => HTTPService.post(`site/${siteSlug}/service/reorder`, payload),
}
