<template>
  <b-card>
    <services-list />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ServicesList from './ServicesList.vue'

export default {
  components: {
    BCard,
    ServicesList,
  },
}
</script>
