<template>
  <b-modal
    id="delete-service-popup"
    :visible="visible"
    size="md"
    ok-title="Удалить"
    cancel-title="Отменить"
    ok-variant="danger"
    :title="`Удалить услугу «${currentService.name}»?`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  />
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SiteService from '@/api/http/models/site/service/SiteService'
import { mapGetters } from 'vuex'

export default {
  props: {
    serviceSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      currentService: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await SiteService.getOne(this.currentSite.slug, this.serviceSlug)

    if (response.data) {
      this.currentService = response.data
      this.visible = true
    }
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await SiteService.delete(this.currentSite.slug, this.serviceSlug)

        if (response.status === 'success') {
          this.$emit('deleted', this.serviceSlug)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Услуга «${this.currentService.name}» удалена`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('delete-service-popup')
          })
        }
      }
    },
  },
}
</script>
