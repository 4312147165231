<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div class="h2 mb-4">
      <span id="popover-target-2">
        Выберите услуги вашего сервиса
      </span>
    </div>
    <b-popover
      target="popover-target-2"
      triggers="click"
      placement="left"
      :show.sync="popoverShow"
    >
      <template #title>
        Выберите услуги
      </template>
      <p class="mb-1">
        Выберите услуги, которые оказывает ваш сервис.
        Вы сможете вернуться к выбору в любой момент
      </p>
      <div class="text-right">
        <b-button
          variant="outline-primary"
          size="sm"
          @click.prevent="onOkPopoverShow"
        >
          Ок, понятно
        </b-button>
      </div>
    </b-popover>
    <div class="row mb-4">
      <div class="col-12 col-md-6 col-lg-4">
        <label
          for="search"
        >
          Поиск:
        </label>
        <b-form-input
          id="search"
          v-model="search"
        />
      </div>
    </div>
    <div class="mb-2 d-md-flex justify-content-between">
      <div>
        <b-button
          class="mb-2 d-flex align-items-center"
          variant="primary"
          @click.prevent="showAddPopup = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
            class="mr-1"
          />
          Добавить услугу
        </b-button>
      </div>
      <div class="d-md-flex align-items-center">
        <b-button
          class="d-flex align-items-center mr-1 my-1 my-md-0"
          variant="primary"
          :disabled="generationRunning ? true : false"
          @click="onGenerate"
        >
          <template v-if="generationRunning">
            Идет генерация страниц
            <feather-icon
              icon="LoaderIcon"
              size="18"
              class="ml-1"
            />
          </template>
          <template v-else>
            <feather-icon
              icon="RepeatIcon"
              size="18"
              class="mr-1"
            />
            Сгенерировать страницы
          </template>
        </b-button>
        <b-button
          v-if="$route.name !== 'init'"
          class="d-flex align-items-center"
          variant="primary"
          :to="{name: 'site_services_sort'}"
        >
          <feather-icon
            icon="GridIcon"
            size="18"
            class="mr-1"
          />
          Сортировка
        </b-button>
      </div>
    </div>
    <div class="">
      <div class="accordion-label__content">
        <div class="row align-items-center w-100">
          <div class="col-12 col-lg-4">
            Название услуги
          </div>
          <div class="col-12 col-lg-3">
            Slug
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="services.length"
      class="accordion"
      role="tablist"
    >
      <div
        v-for="(service, index) in searchedServices"
        :key="index"
        class="accordion__item "
      >
        <div
          v-b-toggle="`accordion-${service.slug}`"
          role="tab"
          class="accordion-label"
        >
          <div class="accordion-label__content">
            <div class="row w-100 align-items-center">
              <div class="col-12 col-lg-4">
                <div class="d-flex align-items-center">
                  <div
                    class="mr-2"
                    @click.stop
                  >
                    <b-form-checkbox
                      :checked="selectedServices.some(item => item.serviceSlug === service.slug)"
                      :indeterminate="selectedServices.some(item => item.serviceSlug === service.slug && !item.AllSelectedServices)"
                      @change="toggleCheckService(service.slug)"
                    />
                  </div>
                  <feather-icon
                    icon="FolderIcon"
                    size="21"
                    class="mr-1"
                  />
                  {{ service.name }}
                </div>
              </div>
              <div class="col-12 col-lg-3">
                <code>{{ service.slug }}</code>
              </div>
              <div class="col-12 col-lg-3">
                <div
                  v-if="!service.is_read_only"
                  class="accordion-label-custom"
                >
                  <div class="accordion-label-custom__icon">
                    <feather-icon
                      icon="StarIcon"
                      size="12"
                    />
                  </div>
                  <div class="accordion-label-custom__text">
                    Custom
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-2">
                <div
                  v-if="!service.is_read_only"
                  class="d-flex justify-content-end align-items-center"
                >
                  <b-button
                    v-b-tooltip.hover.left="'Редактировать'"
                    variant="link"
                    size="sm"
                    @click.stop="editedServiceSlug = service.slug"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                      size="18"
                    />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.left="'Удалить'"
                    variant="link"
                    size="sm"
                    @click.stop="deletedServiceSlug = service.slug"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="18"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-collapse
          :id="`accordion-${service.slug}`"
          accordion="my-accordion"
          role="tabpanel"
          @show="currentServiceSlug = service.slug"
        >
          <sub-services
            v-if="currentServiceSlug === service.slug"
            :key="service.slug"
            :parent-service-slug="service.slug"
            :parent-service-is-selected="selectedServices.some(item => item.serviceSlug === service.slug)"
            @changeSubService="changeSubService"
            @selectedSubService="selectServicesWithoutSubServices"
          />
        </b-collapse>
      </div>
    </div>
    <div v-else>
      Пока нет ни одной услуги
    </div>

    <add-service-popup
      v-if="showAddPopup"
      :parent-service-slug="null"
      @added="addService"
      @hidden="showAddPopup = false"
    />
    <delete-service-popup
      v-if="deletedServiceSlug"
      :service-slug="deletedServiceSlug"
      @deleted="deleteService"
      @hidden="deletedServiceSlug = null"
    />
    <edit-service-popup
      v-if="editedServiceSlug"
      :service-slug="editedServiceSlug"
      @updated="updateService"
      @hidden="editedServiceSlug = null"
    />
  </b-overlay>
</template>

<script>
import SiteService from '@/api/http/models/site/service/SiteService'
import Site from '@/api/http/models/site/site/Site'
import {
  BCollapse, BOverlay, BFormInput, BFormCheckbox, BButton, BPopover, VBToggle, VBTooltip, VBPopover,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import { mapGetters } from 'vuex'
import SubServices from './SubServices.vue'
import AddServicePopup from './AddServicePopup.vue'
import DeleteServicePopup from './DeleteServicePopup.vue'
import EditServicePopup from './EditServicePopup.vue'

export default {
  components: {
    BCollapse,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    BButton,
    BPopover,
    SubServices,
    AddServicePopup,
    DeleteServicePopup,
    EditServicePopup,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      showActivateLoading: false,
      showLoading: true,
      services: [],
      selectedServices: [],
      currentServiceSlug: null,
      deletedServiceSlug: null,
      editedServiceSlug: null,
      showAddPopup: false,
      search: '',
      popoverShow: false,
      generationRunning: false,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    searchedServices() {
      return this.services.filter(service => {
        const searchValue = this.search.toLowerCase().trim()
        const name = service.name && service.name.toLowerCase().indexOf(searchValue) !== -1
        const slug = service.slug && service.slug.toLowerCase().indexOf(searchValue) !== -1
        return name || slug
      })
    },
  },
  async created() {
    const response = await SiteService.getAll(this.currentSite.slug)

    if (response.data) {
      this.selectedServices = response.data.filter(service => service.is_selected).map(service => ({
        serviceSlug: service.slug,
        AllSelectedServices: service.available_children === service.selected_children,
      }))
      this.services = response.data
      this.showLoading = false
      this.popoverShow = !localStorage.servicePopover
      this.$emit('selected', this.selectedServices)
    }
  },
  mounted() {
    if (this.currentSite.generation_pages_running) {
      this.generationRunning = true
    } else {
      this.generationRunning = false
    }
  },
  methods: {
    onOkPopoverShow() {
      localStorage.servicePopover = true
      this.popoverShow = false
    },
    async toggleCheckService(serviceSlug) {
      const index = this.selectedServices.findIndex(item => item.serviceSlug === serviceSlug)
      if (index !== -1) {
        const response = await SiteService.unselect(this.currentSite.slug, serviceSlug)
        if (response.status === 'success') {
          this.selectedServices.splice(index, 1)
        }
      } else {
        const response = await SiteService.select(this.currentSite.slug, serviceSlug, true)
        if (response.status === 'success') {
          this.selectedServices.push({
            serviceSlug,
            AllSelectedServices: true,
          })
        }
      }
      this.$emit('selected', this.selectedServices)
    },
    addService(service) {
      this.services.unshift(service)
      if (service.is_selected) {
        this.selectedServices.push({
          serviceSlug: service.slug,
          AllSelectedServices: service.available_children === service.selected_children,
        })
      }
    },
    deleteService(serviceSlug) {
      const index = this.services.findIndex(cur => cur.slug === serviceSlug)
      const selIndex = this.selectedServices.findIndex(cur => cur === serviceSlug)

      if (index !== -1) {
        this.services.splice(index, 1)
      }

      if (selIndex !== -1) {
        this.selectedServices.splice(selIndex, 1)
      }
    },
    updateService(service) {
      const index = this.services.findIndex(cur => cur.slug === service.old_service.slug)
      const selIndex = this.selectedServices.findIndex(cur => cur === service.old_service.slug)

      if (index !== -1) {
        this.services.splice(index, 1, service.new_service)
      }

      if (selIndex !== -1) {
        this.selectedServices.splice(selIndex, 1)
      }

      if (service.new_service.is_selected) {
        this.selectedServices.push({
          serviceSlug: service.new_service.slug,
          AllSelectedServices: service.new_service.available_children === service.selected_children,
        })
      }
    },
    changeSubService(value) {
      const index = this.selectedServices.findIndex(item => item.serviceSlug === value.serviceSlug)
      this.selectedServices[index].AllSelectedServices = value.subServicesAllChecked
    },
    async selectServicesWithoutSubServices(serviceSlug, done) {
      const response = await SiteService.select(this.currentSite.slug, serviceSlug, false)
      if (response.status === 'success') {
        this.selectedServices.push({
          serviceSlug,
          AllSelectedServices: false,
        })
      }
      await done()
    },
    async onGenerate() {
      const responseGenerate = await Site.generate(this.currentSite.slug)

      if (responseGenerate.status === 'success') {
        this.generationRunning = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Генерация страниц запущена',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else if (responseGenerate.errors) {
        this.mixinErrorsParse(responseGenerate.errors)
      }
    },
  },
}
</script>
