<template>
  <b-modal
    id="edit-service-popup"
    :visible="visible"
    size="md"
    ok-only
    ok-title="Сохранить"
    :title="`Редактирование услуги «${currentService.name}»?`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="row">
        <div class="col-12">
          <b-form-group
            label="Обложка услуги"
          >
            <input-file
              v-model="updateService.cover_image_key"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Иконка услуги"
          >
            <input-file
              v-model="updateService.svg_icon"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Название"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="updateService.name"
              v-validate="'required'"
              name="name"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Slug (ЧПУ)"
            label-for="slug"
          >
            <b-form-input
              id="slug"
              v-model="updateService.slug"
              name="slug"
            />
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SiteService from '@/api/http/models/site/service/SiteService'
import { mapGetters } from 'vuex'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import InputFile from '@core/components/input/InputFile.vue'

import TranslitRusToLat from '../../../@core/utils/TranslitRusToLat'

export default {
  components: {
    BFormGroup,
    BFormInput,
    InputFile,
  },
  mixins: [mixinErrorsParse],
  props: {
    serviceSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      currentService: {
        name: '',
        slug: '',
      },
      updateService: {
        name: '',
        slug: '',
        cover_image_key: '',
        svg_icon: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await SiteService.getOne(this.currentSite.slug, this.serviceSlug)

    if (response.data) {
      this.currentService = response.data
      this.updateService = { ...this.currentService }
      this.updateService.cover_image_key = this.currentService.cover_image && this.currentService.cover_image.key
      this.updateService.svg_icon = this.currentService.svg_icon && this.currentService.svg_icon.key
      this.visible = true
    }
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.updateService.slug = TranslitRusToLat(this.updateService.slug)

      if (this.updateService.name && !this.updateService.slug) {
        this.updateService.slug = TranslitRusToLat(this.updateService.name)
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await SiteService.update(this.currentSite.slug, this.serviceSlug, this.updateService)

        if (response.status === 'success' && response.data) {
          this.$emit('updated', {
            old_service: this.currentService,
            new_service: response.data,
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Услуга изменена',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-service-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при изменении услуги',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
