<template>
  <b-modal
    id="add-service-popup"
    :visible="visible"
    size="md"
    ok-only
    ok-title="Сохранить"
    :title="title"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="row">
        <div class="col-12">
          <b-form-group
            label="Обложка услуги"
          >
            <input-file
              v-model="currentService.cover_image_key"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Иконка услуги"
          >
            <input-file
              v-model="currentService.svg_icon"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Название"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="currentService.name"
              v-validate="'required'"
              name="name"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Slug (ЧПУ)"
            label-for="slug"
          >
            <b-form-input
              id="slug"
              v-model="currentService.slug"
              name="slug"
            />
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SiteService from '@/api/http/models/site/service/SiteService'
import { mapGetters } from 'vuex'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import InputFile from '@core/components/input/InputFile.vue'

import TranslitRusToLat from '../../../@core/utils/TranslitRusToLat'

export default {
  components: {
    BFormGroup,
    BFormInput,
    InputFile,
  },
  mixins: [mixinErrorsParse],
  props: {
    parentServiceSlug: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Добавление услуги',
    },
  },
  data() {
    return {
      visible: false,
      currentService: {
        parent_slug: this.parentServiceSlug,
        name: '',
        slug: '',
        cover_image_key: '',
        svg_icon: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.currentService.slug = TranslitRusToLat(this.currentService.slug)

      if (this.currentService.name && !this.currentService.slug) {
        this.currentService.slug = TranslitRusToLat(this.currentService.name)
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await SiteService.add(this.currentSite.slug, this.currentService)

        if (response.status === 'success' && response.data) {
          this.$emit('added', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Услуга добавлена',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-service-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении услуги',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
